var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ApolloQuery',{attrs:{"deep":true,"query":require('../graphql/Campaigns.gql'),"variables":{ filter: _vm.filter }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
return [(loading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v("An error occured")]):(data)?_c('div',{staticClass:"result apollo"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.dd_items,"label":"Filter"},on:{"change":function($event){return query.refetch()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('v-row',{staticClass:"mx-3",attrs:{"align":"center"}},[_c('v-data-table',{staticClass:"elevation-3",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.transform_req(data.campaigns),"items-per-page":10,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.pdf",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","target":"_blank","href":item.pdf}},[_vm._v(" Download "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-outline ")])],1)]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.address)}})]}},{key:"item.accudata_status",fn:function(ref){
var item = ref.item;
return [(item.accudata_status)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e(),(!item.accudata_status)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_vm._e()]}},{key:"item.osiTransferId",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","target":"_blank","href":'https://dashboard.stripe.com/payments/' +
                    item.osiTransferId}},[_vm._v(" Open "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)})],1)],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("Loading...")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }