<template>
  <v-container fluid>
    <!-- Apollo watched Graphql query -->
    <ApolloQuery
      :deep="true"
      :query="require('../graphql/Campaigns.gql')"
      :variables="{ filter }"
    >
      <template slot-scope="{ result: { loading, error, data }, query }">
        <!-- Loading -->
        <div v-if="loading" class="loading apollo">Loading...</div>

        <!-- Error -->
        <div v-else-if="error" class="error apollo">An error occured</div>

        <!-- Result -->
        <div v-else-if="data" class="result apollo">
          <v-row align="center">
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                :items="dd_items"
                @change="query.refetch()"
                label="Filter"
                v-model="filter"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center" class="mx-3">
            <v-data-table
              :headers="headers"
              :items="transform_req(data.campaigns)"
              :items-per-page="10"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="elevation-3"
              style="width: 100%"
            >
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.pdf="{ item }">
                <v-btn color="primary" target="_blank" :href="item.pdf">
                  Download
                  <v-icon right dark>
                    mdi-file-outline
                  </v-icon>
                </v-btn>
              </template>

              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.address="{ item }">
                <span v-html="item.address"></span>
              </template>

              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.accudata_status="{ item }">
                <v-icon v-if="item.accudata_status" color="green">
                  mdi-check
                </v-icon>
                <v-icon v-if="!item.accudata_status" color="red">
                  mdi-close
                </v-icon>
              </template>

              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.osiTransferId="{ item }">
                <v-btn
                  color="primary"
                  target="_blank"
                  :href="
                    'https://dashboard.stripe.com/payments/' +
                      item.osiTransferId
                  "
                >
                  Open
                  <v-icon right dark>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-row>
        </div>

        <!-- No result -->
        <div v-else class="no-result apollo">Loading...</div>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "MainTable",

  methods: {
    transform_req(d) {
      let format_date = "YY-MM-DD";
      console.log(d);
      let output = [];
      d.forEach((row) => {
        row.agentName = row.user.name;
        row.address = `${row.street}<br>${row.city} ${row.state} ${row.zip}`;
        // todo: get right url
        row.pdf = "https://templates.sendmore.com/seminar_helper/" + row.id;

        row.accudata_status = row.accudata_order_id != null;

        let eventDate;
        let eventDates = [];

        if (row.events.length > 1) {
          eventDate = moment(row.events[0].start_date);

          row.events.forEach((element) => {
            eventDates.push(moment(element.start_date).format(format_date));
            if (moment(element.start_date) < eventDate) {
              eventDate = moment(element.start_date);
            }
          });
        } else {
          eventDate = moment(row.events[0].start_date);
          eventDates = [eventDate.format(format_date)];
        }

        row.eventDate = eventDates.sort().join(", ");
        row.created_clean = moment(row.created_at).format(format_date);
        if (row.mail_date) {
          row.maildate_clean = moment(row.mail_date).format(format_date);
        } else {
          console.log(eventDate);
          row.maildate_clean =
            "Sending: " + eventDate.subtract(28, "day").format(format_date);
        }
        row.status_c = row.status.toLowerCase();
        row.status_c = row.status_c[0].toUpperCase() + row.status_c.slice(1);

        output.push(row);
      });
      return output;
    },
  },
  data() {
    return {
      sortBy: "dbId",
      sortDesc: false,
      dd_items: [
        { text: "Not Complete", value: "pending" },
        { text: "Complete", value: "complete" },
      ],
      filter: "pending",

      headers: [
        { text: "ID", value: "id" },
        { text: "First Name", value: "agentName" },
        { text: "Venue Name", value: "venue_name" },
        { text: "Event Date(s)", value: "eventDate" },
        { text: "Address", value: "address" },
        { text: "Count", value: "requested_count" },
        { text: "OSI Transfer", value: "osi_transfer_id" },
        { text: "AccuData", value: "accudata_status" },
        { text: "Mail Status", value: "status_c" },
        { text: "Created", value: "created_clean" },
        { text: "Mail Date", value: "maildate_clean" },
        { text: "PDF", value: "pdf" },
      ],
    };
  },
};
</script>
